import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider, useSelector } from 'react-redux';
import { CircularProgress, CssBaseline } from '@mui/material';

// local modules
//import theme from '../theme';
import theme from '../theme/theme1.js';

import { persistor, store } from '../redux/store';
import ProjectRoutes from '../router';
import Login from '../pages/Login/login';
import { ToastContainer } from 'react-toastify';
import NotionRoute, { NotionCatUpdateRoute } from '../router/NotionRoute.js';

const App2 = () => {
  const userInfo = useSelector((state) => state.SliceDB.value);

  // Check if the current path is '/notion'
  const isNotionRoute = window.location.pathname === '/notion';
  const isNotionCatUpdateRoute = window.location.pathname === '/notionCategoryUpdate';

  // If it's the Notion route, render NotionRoute, else check login and render ProjectRoutes or Login
  if (isNotionRoute) {
    return <NotionRoute />;
  }
  if (isNotionCatUpdateRoute) {
    return <NotionCatUpdateRoute />;
  } else {
    return userInfo?.login?.userId ? <ProjectRoutes /> : <Login />;
  }
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <ToastContainer />
      </div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div id="loader" style={{ display: 'none' }} className="mui-spinner">
            <CircularProgress
              //style={{ color: 'white' }}
              size={60}
              sx={{
                animationDuration: '500ms'
              }}
            />
          </div>
          <App2 />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
