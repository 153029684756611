import React, { useState } from 'react';
import {
  FormControl,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import '../Notion.css';
import SearchIcon from '@mui/icons-material/Search';
import ItemFieldSearchModal from './ItemFieldSearchModal';

const NotionUpdateGrid = ({ gridData, setGridData, vMediaQuery, packingUnit, xMediaQuery }) => {
  const [openSearchCatModal, setSearchCatModal] = useState(false);
  const [searchProps, setSearchProps] = useState({ field: '', value: '' });

  const handleItemCatChange = (e, rowId) => {
    const { value } = e.target;

    setGridData((prev) =>
      prev.map((item) =>
        item.id === rowId
          ? {
              ...item,
              CategoryName: value,
              // Reset related fields only if value is empty
              ...(value === '' && {
                CategoryCode: '',
                SubGroupCode: '',
                SubGroupName: '',
                MainGroupCode: '',
                MainGroupName: ''
              })
            }
          : item
      )
    );
  };

  const handleChange = (event, rowId) => {
    const { name, value } = event.target;

    setGridData((prevGridData) =>
      prevGridData.map((row) => (row.id === rowId ? { ...row, [name]: value } : row))
    );
  };

  const handleItemCatSearch = (e, fieldName, row) => {
    if (e.key === 'Enter' || e._reactName === 'onClick') {
      if (!row || !row.id) {
        console.error('Error: Row data is undefined.');
        return;
      }

      setSearchProps({
        field: fieldName,
        value: row?.CategoryName || '',
        rowId: row.id
      });
      setSearchCatModal(true);

      setTimeout(() => {
        document.getElementById('anyData')?.focus();
      }, 500);
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        className="table"
        sx={{
          height: '50vh !important',
          width: '100%'
        }}>
        <Table
          size="medium"
          sx={{
            '& .MuiTableCell-root': {
              borderRight: '1px solid #d0d4e4 !important',
              textAlign: 'left'
            }
          }}
          stickyHeader>
          <TableHead>
            <TableRow className="table-head">
              <TableCell sx={{ textAlign: 'left', width: '10%' }}>Page</TableCell>
              <TableCell sx={{ textAlign: 'left', width: '8%' }}>Sub Page</TableCell>
              <TableCell sx={{ textAlign: 'left', width: '8%' }}>Narration</TableCell>
              <TableCell sx={{ textAlign: 'left', width: '5%' }}>Packing Unit</TableCell>
              <TableCell sx={{ textAlign: 'left', width: '10%' }}>Category</TableCell>
              <TableCell sx={{ width: '6%' }}>Sub Group</TableCell>
              <TableCell sx={{ width: '6%' }}>Main Group</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {gridData?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row?.MainPageName}</TableCell>
                <TableCell>{row?.PageName}</TableCell>
                <TableCell>{row?.Narration}</TableCell>
                <TableCell>
                  <FormControl fullWidth>
                    <Select
                      value={row?.Packing_Unit || packingUnit[0]?.UId}
                      onChange={(e) => handleChange(e, row.id)}
                      name="Packing_Unit">
                      {packingUnit?.map((unit) => (
                        <MenuItem key={unit.UId} value={unit.UId}>
                          {unit.Name1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <TextField
                    inputProps={{
                      tabIndex: 104
                    }}
                    fullWidth
                    name="CategoryName"
                    autoComplete="off"
                    value={row?.CategoryName || ''}
                    onChange={(e) => handleItemCatChange(e, row.id)}
                    onKeyDown={(e) => handleItemCatSearch(e, 'Category', row)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                          <SearchIcon onClick={(e) => handleItemCatSearch(e, 'Category', row)} />
                        </InputAdornment>
                      )
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        color: '#0000009e !important',
                        WebkitTextFillColor: '#0000009e !important',
                        opacity: 1
                      }
                    }}
                    inputProps={{
                      tabIndex: 104
                    }}
                    fullWidth
                    name="SubGroupName"
                    autoComplete="off"
                    value={row?.SubGroupName || ''}
                    onChange={(e) => handleItemCatChange(e, row.id)}
                    disabled={true}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        color: '#0000009e !important',
                        WebkitTextFillColor: '#0000009e !important',
                        opacity: 1
                      }
                    }}
                    inputProps={{
                      tabIndex: 104
                    }}
                    fullWidth
                    name="MainGroupName"
                    autoComplete="off"
                    value={row?.MainGroupName || ''}
                    onChange={(e) => handleItemCatChange(e, row.id)}
                    disabled={true}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ItemFieldSearchModal
        xTitle={'Category Modal'}
        open={openSearchCatModal}
        handleClose={() => {
          setSearchCatModal(false);
        }}
        searchProps={searchProps}
        onRowSelect={(selectedRow) => {
          setGridData((prevGridData) =>
            prevGridData.map((row) =>
              row.id === searchProps?.rowId
                ? {
                    ...row,
                    SubGroupCode: selectedRow.SubCategory,
                    SubGroupName: selectedRow.SubCategoryName,
                    MainGroupCode: selectedRow.MainGroupCode,
                    MainGroupName: selectedRow.MainGroupName,
                    CategoryCode: selectedRow.Code,
                    CategoryName: selectedRow.Name
                  }
                : row
            )
          );
          // setSearchCatModal(false);
        }}
        anyData={searchProps?.value || ''}
        vMediaQuery={vMediaQuery}
        xMediaQuery={xMediaQuery}
        setGridData={setGridData}
      />
    </>
  );
};

export default NotionUpdateGrid;
