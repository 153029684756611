import { Box, Card, CardContent, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CmpButtonOld, CmpInputNotion, CmpTypography } from '../../component/ToolBox/ToolBox';
import '../Notion/Notion.css';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { fAlertToast } from '../../Utility/Utilitys';
import { fAttributesAPI, notionFlyerPageUpdateGravityAPI } from './action';
import { httpRequest } from '../../Server/Axios';
import NotionUpdateGrid from './processComponent/NotionUpdateGrid';

const NotionCategoryUpdate = () => {
  const vMediaQuery = useMediaQuery('(max-width:650px)');
  const xMediaQuery = useMediaQuery('(max-width:900px)');
  const [gridData, setGridData] = useState([]);
  const [infoData, setInfoData] = useState([]);
  const [flyerLocData, setFlyerLocData] = useState([]);
  const [packingUnit, setPackingUnit] = useState([]);
  const location = useLocation();

  // Extract the ID from the query string
  const queryParams = new URLSearchParams(location.search);
  const ID = queryParams.get('id'); // Corrected to fetch 'ID' from the query string

  const fetchFlyerDetails = async () => {
    if (ID) {
      try {
        const response = await httpRequest(
          {
            method: 'POST',
            url: '/bakeryPreBooking/getFlyerDetails', // Only relative path, not full URL
            data: { ID }
          },
          true
        ); // Passing 'true' to use REACT_APP_BASE_URL_NOTION

        setInfoData(response.data.FlyerHeader);
        setFlyerLocData(response.data.FlyerLocations);

        const GridID = response.data.FlyerPages?.map((obj, i) => ({
          ...obj,
          id: i + 1
        }));
        setGridData(GridID);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };
  console.log(packingUnit);

  useEffect(() => {
    fetchFlyerDetails();
    fAttributes();
  }, [location.search]);

  const fAttributes = async () => {
    const result = await fAttributesAPI();

    if (result) {
      setPackingUnit(result);
    }
  };
  console.log(gridData);

  const fSave = async () => {
    const items = gridData?.map((item) => ({
      Page: item?.Page,
      CategoryCode: item?.CategoryCode,
      CategoryName: item?.CategoryName,
      MainPage: item?.MainPage,
      Packing_Unit: item?.Packing_Unit == null ? '' : item?.Packing_Unit,
      NDX_ROW_ID: item?.NDX_ROW_ID
    }));

    const obj = {
      ID: infoData?.ID,
      Line: items
    };

    try {
      const result = await notionFlyerPageUpdateGravityAPI(obj);

      if (result) {
        fAlertToast('SUCCESS', 'Data Saved successfully');
      } else {
        fAlertToast('ERROR', `Data didn't post successfully`);
      }
    } catch (error) {
      console.error('Error in fSave:', error);
      fAlertToast('ERROR', 'Something went wrong while saving');
    }

    fetchFlyerDetails(); // Refresh data after save
  };

  const confrimApprove = async () => {
    confirmAlert({
      title: 'Save',
      message: 'Are you sure?',
      buttons: [
        {
          className: 'alertBtn',
          label: 'Yes',
          onClick: () => {
            console.log('Selected Rows:', gridData);
            fSave();
          }
        },
        {
          className: 'alertBtn',
          label: 'No',
          onClick: () => console.log('Approval Canceled')
        }
      ]
    });
  };
  return (
    <div className="notion-container">
      <div>
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1, pt: 2 }}>
        <Grid container columnSpacing={2} paddingTop={vMediaQuery ? 0 : 2}>
          {/* Info Section */}
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h6" gutterBottom>
              Flyer Information
            </Typography>
            <Box className="notion-info">
              <Grid container spacing={2}>
                <Grid item xs={3} sm={3}>
                  <CmpTypography xcomponent={'span'} xText={'Flyer ID'} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <CmpTypography xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={7}>
                  <CmpInputNotion
                    xTextAlign="left"
                    xReadOnly={true}
                    xType={'text'} // text/number/password
                    xValue={infoData?.ID}
                    xName={'ID'}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                    xTabIndex={'4'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3} sm={3}>
                  <CmpTypography xcomponent={'span'} xText={'Flyer Name'} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <CmpTypography xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={7}>
                  <CmpInputNotion
                    xTextAlign="left"
                    xValue={infoData?.Name1}
                    xType={'text'} // text/number/password
                    xName={'Name1'}
                    xReadOnly={true}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                    xTabIndex={'4'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3} sm={3}>
                  <CmpTypography xcomponent={'span'} xText={'From Date'} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <CmpTypography xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={7}>
                  <CmpInputNotion
                    xTextAlign="left"
                    xValue={moment(infoData?.FromDate).format('DD/MM/YYYY')}
                    xType={'text'} // text/number/password
                    xName={'FromDate'}
                    xReadOnly={true}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                    xTabIndex={'4'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3} sm={3}>
                  <CmpTypography xcomponent={'span'} xText={'To Date'} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <CmpTypography xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={7}>
                  <CmpInputNotion
                    xTextAlign="left"
                    xValue={moment(infoData?.ToDate).format('DD/MM/YYYY')}
                    xType={'text'} // text/number/password
                    xName={'ToDate'}
                    xReadOnly={true}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                    xTabIndex={'4'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3} sm={3}>
                  <CmpTypography xcomponent={'span'} xText={'Status'} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <CmpTypography xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={7}>
                  <CmpInputNotion
                    xTextAlign="left"
                    xValue={infoData?.Status}
                    xType={'text'} // text/number/password
                    xName={'Status'}
                    xReadOnly={true}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                    xTabIndex={'4'}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Stores Card */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Locations
            </Typography>
            <div>
              <Card className="notion-card">
                <CardContent>
                  <Box sx={{ height: '12vh', overflow: 'auto', padding: '0 !important' }}>
                    {flyerLocData?.map((loc, index) => (
                      <Typography variant="body1" sx={{ paddingY: '2px' }} key={index}>
                        {loc.locName}
                      </Typography>
                    ))}
                  </Box>
                </CardContent>
              </Card>
              <div style={{ marginTop: vMediaQuery ? '10px' : '20px', textAlign: 'center' }}>
                <CmpButtonOld
                  xLabel={'Save'}
                  xvariant={'contained'}
                  xonClick={confrimApprove}
                  // xDisable={infoData?.Status === 'POST'}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container>
          {/* Pages Data Grid */}
          <Grid item xs={12}>
            <Box sx={{ width: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Pages
              </Typography>
              <NotionUpdateGrid
                gridData={gridData}
                setGridData={setGridData}
                vMediaQuery={vMediaQuery}
                xMediaQuery={xMediaQuery}
                packingUnit={packingUnit}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default NotionCategoryUpdate;
