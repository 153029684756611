import React, { useEffect, useRef, useState } from 'react';
import { Modal, Box, TextField, IconButton, Grid, Typography, InputAdornment } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {
  deleteLineItemsAPI,
  getNotionFlyerItemsAPI,
  getUnitAPI,
  updateFlyerItemsAPI
} from '../action';
import { fAlertToast } from '../../../Utility/Utilitys';
import {
  CmpButtonOld,
  CmpInputField,
  CmpInputNotion,
  CmpSelectField,
  CmpTypography,
  CmpTypographyField
} from '../../../component/ToolBox/ToolBox';
import ItemSearchModal from './ItemSearchModal';

const columns = [
  { field: 'id', headerName: 'GridID', flex: 0.8, hide: true },
  { field: 'ID', headerName: 'ID', flex: 0.8, hide: true },
  { field: 'Page', headerName: 'Page', flex: 0.6, hide: true },
  { field: 'ItmCode', headerName: 'Item Code', width: 100, hide: true },
  { field: 'ItmName', headerName: 'Item Name', flex: 2 },
  { field: 'UnitCode', headerName: 'Unit Code', width: 120, hide: true },
  { field: 'Brand Name', headerName: 'Brand', flex: 0.8 },
  { field: 'Category', headerName: 'Category', flex: 0.8 },
  { field: 'Main Category', headerName: 'Main Category', flex: 0.8 },
  { field: 'Sub Category', headerName: 'Sub Category', flex: 0.8 },
  { field: 'UnitName', headerName: 'Unit', flex: 0.6 },
  { field: 'Lprate', headerName: 'Lprate', flex: 0.5, align: 'center', headerAlign: 'center' },
  {
    field: 'CostPrice',
    headerName: 'Cost Price',
    flex: 0.5,
    align: 'center',
    headerAlign: 'center'
  },
  { field: 'RSP', headerName: 'RSP', flex: 0.5, align: 'left', headerAlign: 'left' },
  {
    field: 'RSPWithVat',
    headerName: 'RSP(VAT)',
    flex: 0.5,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'DiscountPerc',
    headerName: 'Disc %',
    align: 'center',
    headerAlign: 'center',
    flex: 0.5
  },
  { field: 'Discount Amount', headerName: 'Disc Amount', flex: 0.5 },
  { field: 'PromoRSP', headerName: 'PromoRSP', flex: 0.5 },
  {
    field: 'Promo RSP + VAT',
    headerName: 'PromoRSP(VAT)',
    align: 'center',
    headerAlign: 'center',
    flex: 0.5
  },
  { field: 'GP Amount', headerName: 'GPAmount', flex: 0.5, align: 'center', headerAlign: 'center' },
  { field: 'GP %', headerName: 'GP %', flex: 0.5 },
  { field: 'Quantity per Promotion', headerName: 'Qty/Promotion', flex: 0.5 },
  {
    field: 'Support Credit',
    headerName: 'Support Credit',
    flex: 0.5,
    align: 'center',
    headerAlign: 'center'
  },
  { field: 'Vendor Name', headerName: 'Vendor Name', flex: 1 },
  {
    field: 'fixedSupport',
    headerName: 'Fixed Support',
    flex: 0.5,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'cutPrice',
    headerName: 'Cut Price',
    flex: 0.5,
    align: 'center',
    headerAlign: 'center'
  },
  { field: 'remarks', headerName: 'Remarks', flex: 1 }
];

const SearchModal = ({
  open,
  handleClose,
  items,
  infoData,
  vMediaQuery,
  xMediaQuery,
  uMediaQuery,
  aMediaQuery,
  flyerLocData,
  setIsPopModalDataChanged
}) => {
  const [unitData, setUintData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [isItemSearchModal, setIsItemSearchModal] = useState(false);
  const saveButtonRef = useRef(null);

  let initailDetailObj = {
    itemCd: '',
    itmName: '',
    unit: '',
    costPrice: 0,
    rsp: 0,
    rspUnitVAT: 0,
    promoRsp: 0,
    promoRspUnitVAT: 0,
    qtyPromo: 0,
    cnAmount: 0,
    fixedSupport: 0,
    cutPrice: 0,
    remarks: ''
  };
  const [lineItems, setLineItems] = useState(initailDetailObj);

  let initHeadError = {
    itemCd: '',
    itmName: '',
    unit: '',
    costPrice: '',
    rsp: '',
    rspUnitVAT: '',
    promoRsp: '',
    promoRspUnitVAT: '',
    qtyPromo: '',
    cnAmount: '',
    fixedSupport: '',
    cutPrice: '',
    remarks: ''
  };
  const [errors, setErrors] = useState(initHeadError);

  const fValidationHead = () => {
    let isValid = true;
    const newError = initHeadError;
    if (lineItems?.itemCd === '' || lineItems?.itemCd == undefined || lineItems?.itemCd === null) {
      newError.itmName = 'ItemCode is required.';
      isValid = false;
    }
    if (
      !lineItems?.itmName ||
      lineItems?.itmName == undefined ||
      lineItems?.itmName === null ||
      lineItems?.itmName === 'Invalid date'
    ) {
      newError.itmName = 'ItemName is required.';
      isValid = false;
    }
    if (lineItems?.unit == '' || lineItems?.unit == undefined || lineItems?.unit === null) {
      newError.unit = 'Unit is required.';
      isValid = false;
    }
    if (
      lineItems?.costPrice == '' ||
      lineItems?.costPrice == undefined ||
      lineItems?.costPrice === null
    ) {
      newError.costPrice = 'CostPrice is required.';
      isValid = false;
    }
    if (lineItems?.rsp == '' || lineItems?.rsp == undefined || lineItems?.rsp === null) {
      newError.rsp = 'Rsp is required.';
      isValid = false;
    }
    if (
      lineItems?.rspUnitVAT == '' ||
      lineItems?.rspUnitVAT == undefined ||
      lineItems?.rspUnitVAT === null
    ) {
      newError.rspUnitVAT = 'RspUnitVAT is required.';
      isValid = false;
    }
    if (
      lineItems?.promoRsp == '' ||
      lineItems?.promoRsp == undefined ||
      lineItems?.promoRsp === null
    ) {
      newError.promoRsp = 'PromoRsp is required.';
      isValid = false;
    }
    if (
      lineItems?.promoRspUnitVAT == '' ||
      lineItems?.promoRspUnitVAT == undefined ||
      lineItems?.promoRspUnitVAT === null
    ) {
      newError.promoRspUnitVAT = 'PromoRspVAT is required.';
      isValid = false;
    }
    if (
      lineItems?.qtyPromo == '' ||
      lineItems?.qtyPromo == undefined ||
      lineItems?.qtyPromo === null
    ) {
      newError.qtyPromo = 'Qty is required.';
      isValid = false;
    }
    if (
      lineItems?.cnAmount == '' ||
      lineItems?.cnAmount == undefined ||
      lineItems?.cnAmount === null
    ) {
      newError.cnAmount = 'CNAmount is required.';
      isValid = false;
    }
    if (
      lineItems?.fixedSupport == '' ||
      lineItems?.fixedSupport == undefined ||
      lineItems?.fixedSupport === null
    ) {
      newError.fixedSupport = 'FixedSupport is required.';
      isValid = false;
    }
    if (
      lineItems?.cutPrice == '' ||
      lineItems?.cutPrice == undefined ||
      lineItems?.cutPrice === null
    ) {
      newError.cutPrice = 'CutPrice is required.';
      isValid = false;
    }
    if (
      lineItems?.remarks == '' ||
      lineItems?.remarks == undefined ||
      lineItems?.remarks === null
    ) {
      newError.remarks = 'Remarks is required.';
      isValid = false;
    }

    setErrors((prevError) => ({
      ...prevError,
      ...newError
    }));

    if (!isValid) {
      const firstInvalidField = Object.keys(newError).find((key) => newError[key]);
      document.querySelector(`[name="${firstInvalidField}"]`)?.focus();
    }

    return isValid;
  };

  const fContent_Focus = (e) => {
    if (e.target.value == 0) {
      setLineItems((obj) => ({
        ...obj,
        [e.target.name]: ''
      }));
    }
  };
  const fContent_Blur = (e) => {
    if (e.target.value == '') {
      setLineItems((obj) => ({
        ...obj,
        [e.target.name]: (0).toFixed(2)
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'itemCd' && value) {
      getUintData(value);
    }

    if (name === 'itmName' && value === '') {
      setLineItems((prevState) => ({
        ...prevState,
        itemCd: '',
        unit: '',
        costPrice: 0,
        rsp: 0,
        rspUnitVAT: 0,
        fixedSupport: 0,
        cutPrice: 0,
        remarks: '',
        [name]: value
      }));
    } else {
      setLineItems((prevState) => ({
        ...prevState,
        [name]: value
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const itemSearchModalClose = () => {
    setIsItemSearchModal(false);

    setTimeout(() => {
      document.getElementById('ItemName')?.focus();
    }, 500);
  };

  const handleSearch = (e) => {
    if (e.key === 'Enter' || e._reactName === 'onClick') {
      setIsItemSearchModal(true);
    }

    setTimeout(() => {
      document.getElementById('anyData')?.focus();
    }, 500);
  };

  const getUintData = async (itemCd) => {
    if (!itemCd) return;

    const obj = {
      v_ItmCd: itemCd,
      v_Unit: '',
      v_Loc: flyerLocData[0]?.loc
    };
    const result = await getUnitAPI(obj);
    setUintData(result);

    if (result && result.length > 0) {
      setLineItems((prevOrder) => ({
        ...prevOrder,
        unit: result[0].UnitCode
      }));
    }
  };

  const fNew = () => {
    setErrors(initailDetailObj);
    setLineItems(initailDetailObj);
    setTimeout(() => {
      document.getElementById('ItemName')?.focus();
    }, 500);
  };

  const fSave = async () => {
    setErrors(initHeadError);

    const isHeadValid = fValidationHead();
    if (!isHeadValid) return;

    const obj = {
      ID: infoData?.ID,
      Page: items?.Page,
      ItmCode: lineItems?.itemCd,
      Unit: lineItems?.unit,
      CostPrice: lineItems?.costPrice,
      RSP: lineItems?.rsp,
      RSPWithVat: lineItems?.rspUnitVAT,
      PromoRSP: lineItems?.promoRsp,
      PromoRSPWithVat: lineItems?.promoRspUnitVAT,
      QtyPerPromotion: lineItems?.qtyPromo,
      CreditNoteAmount: lineItems?.cnAmount,
      MainPage: items?.MainPage,
      MainPageName: items?.MainPageName,
      PageName: items?.PageName,
      fixedSupport: lineItems?.fixedSupport,
      cutPrice: lineItems?.cutPrice,
      remarks: lineItems?.remarks
    };
    const result = await updateFlyerItemsAPI(obj);
    if (result) {
      fAlertToast('SUCCESS', 'Line items saved successfully');
    }
    fNew();
    getNotionFlyerItems();
    setTimeout(() => {
      document.getElementById('ItemName')?.focus();
    }, 500);
    setIsPopModalDataChanged(true);
  };

  const fDelete = async () => {
    const obj = {
      ID: infoData?.ID,
      Page: items?.Page,
      ItmCode: lineItems?.itemCd,
      Unit: lineItems?.unit,
      MainPage: items?.MainPage
    };
    const result = await deleteLineItemsAPI(obj);
    if (result) {
      fAlertToast('SUCCESS', 'Line items deleted successfully');
    }
    fNew();
    getNotionFlyerItems();
    setTimeout(() => {
      document.getElementById('ItemName')?.focus();
    }, 500);
    setIsPopModalDataChanged(true);
  };

  const getNotionFlyerItems = async () => {
    const obj = {
      ID: infoData?.ID,
      Page: items?.Page,
      MainPage: items?.MainPage
    };
    const result = await getNotionFlyerItemsAPI(obj);

    if (result && result?.length) {
      const processedData = result?.map((item, index) => ({
        ...item,
        id: index + 1
      }));
      setGridData(processedData);
    } else {
      setGridData([]);
    }
  };

  const handleRowClick = (params) => {
    const item = params.row;
    setLineItems((prevOrder) => ({
      ...prevOrder,
      itemCd: item?.ItmCode,
      itmName: item?.ItmName,
      unit: item?.UnitCode,
      costPrice: item?.CostPrice,
      rsp: item?.RSP,
      rspUnitVAT: item?.RSPWithVat,
      promoRsp: item?.PromoRSP,
      promoRspUnitVAT: item?.['Promo RSP + VAT'],
      qtyPromo: item?.['Quantity per Promotion'],
      cnAmount: item?.['Support Credit'],
      fixedSupport: item?.fixedSupport,
      cutPrice: item?.cutPrice,
      remarks: item?.remarks
    }));
  };

  const handlePromoRspKeyUp = (e) => {
    const promoRspValue = parseFloat(e.target.value);

    if (promoRspValue && lineItems.unit) {
      const selectedUnit = unitData.find((unit) => unit.UnitCode === lineItems.unit);

      if (selectedUnit) {
        const taxAmount = (selectedUnit.TaxPerc / 100) * promoRspValue;
        const proRSPVAT = promoRspValue + taxAmount + selectedUnit.AddiCessAmt;

        setLineItems((prevOrder) => ({
          ...prevOrder,
          promoRsp: promoRspValue,
          promoRspUnitVAT: proRSPVAT.toFixed(2)
        }));
      }
    }

    setTimeout(() => {
      document.getElementById('promoRspUnitVAT').focus();
    }, 600);
  };

  const handlePromoRspVATKeyUp = (e) => {
    const rspVAT = parseFloat(e.target.value);

    if (rspVAT && lineItems.unit) {
      const selectedUnit = unitData.find((unit) => unit.UnitCode === lineItems.unit);

      if (selectedUnit) {
        const proRspVAT = rspVAT - selectedUnit.AddiCessAmt;
        const promoRSPVAT = (proRspVAT / (100 + selectedUnit.TaxPerc)) * 100;

        setLineItems((prevOrder) => ({
          ...prevOrder,
          promoRspUnitVAT: rspVAT,
          promoRsp: promoRSPVAT.toFixed(2)
        }));
      }
    }

    setTimeout(() => {
      document.getElementById('qtyPromo').focus();
    }, 600);
  };

  const handleCloseModal = () => {
    handleClose();
    fNew();
  };
  // Automatically set costPrice, rsp, and rspUnitVAT when unitData or unit changes
  useEffect(() => {
    if (lineItems.unit && unitData.length > 0) {
      const selectedUnit = unitData.find((unit) => unit.UnitCode === lineItems.unit);
      if (selectedUnit) {
        setLineItems((prevOrder) => ({
          ...prevOrder,
          costPrice: selectedUnit.CostPrice,
          rsp: selectedUnit.RSP,
          rspUnitVAT: selectedUnit.VATRSP
        }));
      }
    }
  }, [lineItems.unit, unitData]);

  // Call getUintData when itemCd is already set or changes
  useEffect(() => {
    if (lineItems.itemCd) {
      getUintData(lineItems.itemCd);
    }
  }, [lineItems.itemCd]);

  useEffect(() => {
    if (open) {
      getNotionFlyerItems();
    }
  }, [open, infoData, items]);

  return (
    <>
      <Modal open={open} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: vMediaQuery ? '95%' : xMediaQuery ? '95%' : '85%',
            overflow: 'auto',
            height: vMediaQuery ? '85vh' : 'auto',
            bgcolor: 'background.paper',
            borderRadius: 3
          }}>
          <div className="notion-search">
            <Typography color="#fff" variant="subtitle1">
              Line Items
            </Typography>
            <CloseIcon onClick={handleCloseModal} sx={{ cursor: 'pointer' }} />
          </div>
          <Grid container sx={{ paddingX: vMediaQuery ? 2 : 4 }} spacing={vMediaQuery ? 0 : 3}>
            <Grid item xs={12} sm={6} md={6}>
              <Box className="notion-infoScr">
                <Grid container spacing={vMediaQuery ? 0 : 2}>
                  <Grid item xs={3.5} sm={3.2} md={2.5}>
                    <CmpTypography xcomponent={'span'} xText={'Flyer ID'} />
                  </Grid>
                  <Grid item xs={0.5} sm={0.8} md={0.8}>
                    <CmpTypography xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={8} sm={8} lg={8.7}>
                    <CmpInputNotion
                      xTextAlign="left"
                      xReadOnly={true}
                      xType={'text'} // text/number/password
                      xValue={infoData?.ID}
                      xName={'ID'}
                      xErrorMessage={''}
                      xOnKeyUp={''}
                      xTabIndex={'4'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={vMediaQuery ? 0 : 2}>
                  <Grid item xs={3.5} sm={3.2} md={2.5}>
                    <CmpTypography xcomponent={'span'} xText={'Flyer Name'} />
                  </Grid>
                  <Grid item xs={0.5} sm={0.8} md={0.8}>
                    <CmpTypography xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={8} sm={8} md={8.7}>
                    <CmpInputNotion
                      xTextAlign="left"
                      xValue={infoData?.Name1}
                      xType={'text'} // text/number/password
                      xName={'Name1'}
                      xReadOnly={true}
                      xErrorMessage={''}
                      xOnKeyUp={''}
                      xTabIndex={'4'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={vMediaQuery ? 0 : 2}>
                  <Grid item xs={3.5} sm={3.2} md={2.5}>
                    <CmpTypography xcomponent={'span'} xText={'Page'} />
                  </Grid>
                  <Grid item xs={0.5} sm={0.8} md={0.8}>
                    <CmpTypography xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={8} sm={8} md={8.7}>
                    <CmpInputNotion
                      xTextAlign="left"
                      xValue={items?.MainPageName}
                      xType={'text'} // text/number/password
                      xName={'MainPageName'}
                      xReadOnly={true}
                      xErrorMessage={''}
                      xOnKeyUp={''}
                      xTabIndex={'4'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={vMediaQuery ? 0 : 2}>
                  <Grid item xs={3.5} sm={3.2} md={2.5}>
                    <CmpTypography xcomponent={'span'} xText={'Sub Page'} />
                  </Grid>
                  <Grid item xs={0.5} sm={0.8} md={0.8}>
                    <CmpTypography xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={8} sm={8} md={8.7}>
                    <CmpInputNotion
                      xTextAlign="left"
                      xValue={items?.PageName}
                      xType={'text'} // text/number/password
                      xName={'PageName'}
                      xReadOnly={true}
                      xErrorMessage={''}
                      xOnKeyUp={''}
                      xTabIndex={'4'}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box className="notion-infoScr">
                <Grid container spacing={vMediaQuery ? 0 : 2}>
                  <Grid item xs={3.5} sm={3.2} md={2.5}>
                    <CmpTypography xcomponent={'span'} xText={'Group'} />
                  </Grid>
                  <Grid item xs={0.5} sm={0.8} md={0.8}>
                    <CmpTypography xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={8} sm={8} md={8.7}>
                    <CmpInputNotion
                      xTextAlign="left"
                      xValue={items?.MainGroupCode + ' ' + '-' + ' ' + items?.MainGroupName}
                      xType={'text'} // text/number/password
                      xName={'MainGroupName'}
                      xReadOnly={true}
                      xErrorMessage={''}
                      xOnKeyUp={''}
                      xTabIndex={'4'}
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={vMediaQuery ? 0 : 2}>
                  <Grid item xs={3.5} sm={3.2} md={2.5}>
                    <CmpTypography xcomponent={'span'} xText={'Sub Group'} />
                  </Grid>
                  <Grid item xs={0.5} sm={0.8} md={0.8}>
                    <CmpTypography xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={8} sm={8} md={8.7}>
                    <CmpInputNotion
                      xTextAlign="left"
                      xValue={items?.SubGroupCode + ' ' + '-' + ' ' + items?.SubGroupName}
                      xType={'text'} // text/number/password
                      xName={'SubGroupName'}
                      xReadOnly={true}
                      xErrorMessage={''}
                      xOnKeyUp={''}
                      xTabIndex={'4'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={vMediaQuery ? 0 : 1}>
                  <Grid item xs={3.5} sm={3.2} md={2.5}>
                    <CmpTypography xcomponent={'span'} xText={'Category'} />
                  </Grid>
                  <Grid item xs={0.5} sm={0.8} md={0.8}>
                    <CmpTypography xcomponent={'span'} xText={':'} />
                  </Grid>
                  <Grid item xs={8} sm={8} md={8.7}>
                    <CmpInputNotion
                      xTextAlign="left"
                      xValue={items?.CategoryCode + ' ' + '-' + ' ' + items?.CategoryName}
                      xType={'text'} // text/number/password
                      xName={'SubGroupName'}
                      xReadOnly={true}
                      xErrorMessage={''}
                      xOnKeyUp={''}
                      xTabIndex={'4'}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ paddingY: vMediaQuery ? 1 : 0, paddingX: vMediaQuery ? 2 : 4 }}
            rowSpacing={{ xs: 1, sm: 1, md: 1 }}
            columnSpacing={{ xs: 0, sm: 0.5, md: 0.2 }}
            columns={{ xs: 12, sm: 12, md: 16 }}
            className={vMediaQuery ? 'notion-search-grid mb-2' : 'notion-search-grid mt-3'}>
            <Grid item xs={9} sm={7} md={6.1}>
              <CmpTypographyField xcomponent={'div'} xText={'Item Name'} />
              <TextField
                className="inputItem"
                fullWidth
                name="itmName"
                id="ItemName"
                autoComplete="off"
                value={lineItems.itmName}
                onChange={handleChange}
                onKeyDown={handleSearch}
                error={!!errors?.itmName && !lineItems?.itmName}
                helperText={!!errors?.itmName && !lineItems?.itmName ? errors?.itmName : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={1.5}>
              <CmpTypographyField xcomponent={'div'} xText={'Unit'} />
              <CmpSelectField
                xTabIndex="1003"
                xLabelText="Unit"
                xValue={lineItems.unit}
                xOnChange={handleChange}
                xName={'unit'}
                xData={unitData}
                xValueMember="UnitCode"
                xDispalyMember="UnitName"
                xDisable={false}
                xError={!!errors?.unit && !lineItems?.unit}
                xErrorMessage={!!errors?.unit && !lineItems?.unit ? errors?.unit : ''}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={3} sm={2} md={0.8}>
              <CmpTypographyField xcomponent={'div'} xText={'CostPrice'} />
              <CmpInputField
                xValue={Number(lineItems.costPrice).toFixed(2)}
                xType={'text'} // text/number/password
                xName={'costPrice'}
                xReadOnly={true}
                xOnChange={handleChange}
                xOnKeyUp={''}
                xError={!!errors?.costPrice && !lineItems?.costPrice}
                xErrorMessage={
                  !!errors?.costPrice && !lineItems?.costPrice ? errors?.costPrice : ''
                }
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={3} sm={2} md={0.8}>
              <CmpTypographyField xcomponent={'div'} xText={'RSP'} />
              <CmpInputField
                xValue={lineItems.rsp}
                xType={'text'} // text/number/password
                xName={'rsp'}
                xReadOnly={true}
                xOnChange={handleChange}
                xError={!!errors?.rsp && !lineItems?.rsp}
                xErrorMessage={!!errors?.rsp && !lineItems?.rsp ? errors?.rsp : ''}
                xOnKeyUp={''}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={3} sm={2} md={0.8}>
              <CmpTypographyField xcomponent={'div'} xText={'RSP(VAT)'} />
              <CmpInputField
                xValue={lineItems.rspUnitVAT}
                xType={'text'} // text/number/password
                xName={'rspUnitVAT'}
                xReadOnly={true}
                xOnChange={handleChange}
                xOnKeyUp={''}
                xError={!!errors?.rspUnitVAT && !lineItems?.rspUnitVAT}
                xErrorMessage={
                  !!errors?.rspUnitVAT && !lineItems?.rspUnitVAT ? errors?.rspUnitVAT : ''
                }
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={3} sm={2} md={0.8}>
              <CmpTypographyField xcomponent={'div'} xText={'Promo RSP'} />
              <CmpInputField
                xTabIndex="1002"
                xValue={lineItems.promoRsp}
                xType={'number'} // text/number/password
                xName={'promoRsp'}
                xId={'promoRsp'}
                xOnChange={handleChange}
                xOnKeyUp={(e) => handlePromoRspKeyUp(e)}
                xError={!!errors?.promoRsp && !lineItems?.promoRsp}
                xErrorMessage={!!errors?.promoRsp && !lineItems?.promoRsp ? errors?.promoRsp : ''}
                xonBlur={fContent_Blur}
                xonFocus={fContent_Focus}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={4} sm={2.4} md={1.2}>
              <CmpTypographyField xcomponent={'div'} xText={'PromoRSP(VAT)'} />
              <CmpInputField
                xTabIndex="1002"
                xValue={lineItems.promoRspUnitVAT}
                xId={'promoRspUnitVAT'}
                xName={'promoRspUnitVAT'}
                xType={'number'}
                xOnChange={handleChange}
                xOnKeyUp={(e) => handlePromoRspVATKeyUp(e)}
                xError={!!errors?.promoRspUnitVAT && !lineItems?.promoRspUnitVAT}
                xErrorMessage={
                  !!errors?.promoRspUnitVAT && !lineItems?.promoRspUnitVAT
                    ? errors?.promoRspUnitVAT
                    : ''
                }
                xonBlur={fContent_Blur}
                xonFocus={fContent_Focus}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={4} sm={1.6} md={1}>
              <CmpTypographyField xcomponent={'div'} xText={'Qty/Promo'} />
              <CmpInputField
                xTabIndex="1002"
                xValue={lineItems.qtyPromo}
                xId={'qtyPromo'}
                xName={'qtyPromo'}
                xOnChange={handleChange}
                xOnKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    setTimeout(() => {
                      document.getElementById('cnAmount').focus();
                    }, 600);
                  }
                }}
                xError={!!errors?.qtyPromo && !lineItems?.qtyPromo}
                xErrorMessage={!!errors?.qtyPromo && !lineItems?.qtyPromo ? errors?.qtyPromo : ''}
                xonBlur={fContent_Blur}
                xonFocus={fContent_Focus}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={4} sm={2} md={1}>
              <CmpTypographyField xcomponent={'div'} xText={'CN Amount'} />
              <CmpInputField
                xTabIndex="1002"
                xValue={lineItems.cnAmount}
                xId={'cnAmount'}
                xName={'cnAmount'}
                xOnChange={handleChange}
                xOnKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    setTimeout(() => {
                      document.getElementById('fixedSupport').focus();
                    }, 600);
                  }
                }}
                xError={!!errors?.cnAmount && !lineItems?.cnAmount}
                xErrorMessage={!!errors?.cnAmount && !lineItems?.cnAmount ? errors?.cnAmount : ''}
                xonBlur={fContent_Blur}
                xonFocus={fContent_Focus}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={4} sm={2} md={1}>
              <CmpTypographyField xcomponent={'div'} xText={'Fixed Support'} />
              <CmpInputField
                xTabIndex="1002"
                xValue={lineItems.fixedSupport}
                xId={'fixedSupport'}
                xName={'fixedSupport'}
                xType={'number'}
                xOnChange={handleChange}
                xOnKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    setTimeout(() => {
                      document.getElementById('cutPrice').focus();
                    }, 600);
                  }
                }}
                xError={!!errors?.fixedSupport && !lineItems?.fixedSupport}
                xErrorMessage={
                  !!errors?.fixedSupport && !lineItems?.fixedSupport ? errors?.fixedSupport : ''
                }
                xonBlur={fContent_Blur}
                xonFocus={fContent_Focus}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={4} sm={2} md={1}>
              <CmpTypographyField xcomponent={'div'} xText={'Cut Price'} />
              <CmpInputField
                xTabIndex="1002"
                xValue={lineItems.cutPrice}
                xId={'cutPrice'}
                xName={'cutPrice'}
                xType={'number'}
                xOnChange={handleChange}
                xOnKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    setTimeout(() => {
                      document.getElementById('remarks').focus();
                    }, 600);
                  }
                }}
                xError={!!errors?.cutPrice && !lineItems?.cutPrice}
                xErrorMessage={!!errors?.cutPrice && !lineItems?.cutPrice ? errors?.cutPrice : ''}
                xonBlur={fContent_Blur}
                xonFocus={fContent_Focus}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={4} sm={8} md={7.6}>
              <CmpTypographyField xcomponent={'div'} xText={'Remarks'} />
              <CmpInputField
                xTabIndex="1002"
                xValue={lineItems.remarks}
                xId={'remarks'}
                xName={'remarks'}
                xOnChange={handleChange}
                xOnKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    fSave();
                  }
                }}
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ paddingX: vMediaQuery ? 2 : 4, paddingY: vMediaQuery ? 0 : 2 }}>
            {/* Data Grid */}
            <Grid item xs={12}>
              <Box sx={{ height: 300, width: '100%' }}>
                <DataGrid
                  className="notion-grid"
                  rows={gridData}
                  columns={columns}
                  onRowClick={handleRowClick}
                  pageSize={5}
                  getRowId={(row) => row.id}
                />
              </Box>
            </Grid>

            {/* Buttons */}
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-evenly"
              marginBottom={vMediaQuery ? 1 : ''}>
              <CmpButtonOld xLabel={'New'} xvariant={'contained'} xonClick={fNew} />
              <CmpButtonOld
                xLabel={'Save'}
                xvariant={'contained'}
                xonClick={fSave}
                // xDisable={infoData?.Status === 'POST'}
                ref={saveButtonRef}
              />
              <CmpButtonOld
                xLabel={'Delete'}
                xvariant={'contained'}
                xonClick={fDelete}
                // xDisable={infoData?.Status === 'POST'}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <ItemSearchModal
        open={isItemSearchModal}
        handleClose={itemSearchModalClose}
        handleSearch={handleSearch}
        locData={flyerLocData[0]?.loc}
        anyDataLineItems={lineItems?.itmName}
        vMediaQuery={vMediaQuery}
        xMediaQuery={xMediaQuery}
        aMediaQuery={aMediaQuery}
        uMediaQuery={uMediaQuery}
        setLineItems={setLineItems}
        items={items}
      />
    </>
  );
};

export default SearchModal;
