import React from 'react';
import { Box, Typography } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

// Custom component for the confirmation message
const ConfirmMessage = ({ selectedRows, xMediaQuery }) => {
  // Calculate total Required Items and Saved Items
  const totalRequiredItems = selectedRows.reduce((sum, row) => sum + (row.NoOfItems || 0), 0);
  const totalSavedItems = selectedRows.reduce((sum, row) => sum + (row.SavedItems || 0), 0);
  return (
    <Box>
      <div style={{ paddingTop: 2, paddingBottom: '16px' }}>
        <Typography className="alertMeg">Required items : {totalRequiredItems}</Typography>
        <Typography className="alertMeg">Saved items : {totalSavedItems}</Typography>
      </div>

      <Typography className="alertmeg">Are you sure you want to post?</Typography>
    </Box>
  );
};

export default ConfirmMessage;
