import { Routes, Route } from 'react-router-dom';
import Notion from '../pages/Notion/Notion';
import NotionCategoryUpdate from '../pages/Notion/NotionCategoryUpdate';

const NotionRoute = () => {
  return (
    <Routes>
      <Route path="/notion" element={<Notion />} />
    </Routes>
  );
};

export default NotionRoute;

export const NotionCatUpdateRoute = () => {
  return (
    <Routes>
      <Route path="/notionCategoryUpdate" element={<NotionCategoryUpdate />} />
    </Routes>
  );
};
