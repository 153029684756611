import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Modal,
  IconButton,
  Divider,
  Grid,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import '../Notion.css';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { notionCatagorySearchAPI } from '../action';
import { CmpTypography } from '../../../component/ToolBox/ToolBox';

const ItemFieldSearchModal = ({
  open = false,
  handleClose = () => {},
  onRowSelect = () => {},
  anyData = '',
  xTitle = '',
  vMediaQuery,
  xMediaQuery
}) => {
  const [searchData, setSearchData] = useState('');
  const [searchGridData, setSearchGridData] = useState([]);
  const [selectedRadioValue, setSelectedRadioValue] = useState('C');
  const initialFetch = useRef(true);

  const handleRadioChange = (event) => {
    setSelectedRadioValue(event.target.value);
  };

  // Columns definition
  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'Code', headerName: 'Code', flex: 1.2 },
    { field: 'Name', headerName: 'Name', flex: 3 },
    { field: 'SubCategory', headerName: 'SubCategory', hide: true },
    {
      field: 'SubCategoryName',
      headerName: 'SubCategoryName',
      flex: 2
    },
    { field: 'MainGroupCode', headerName: 'MainGroupCode', hide: true },
    {
      field: 'MainGroupName',
      headerName: 'MainGroupName',
      flex: 2
    }
  ];

  useEffect(() => {
    setSearchData(anyData);
  }, [anyData]);

  useEffect(() => {
    if (open) {
      if (initialFetch.current && searchData.length > 3) {
        fetchItemData();
        initialFetch.current = false;
      }
    } else {
      initialFetch.current = true;
    }
  }, [open, searchData]);

  useEffect(() => {
    fetchItemData();
  }, [selectedRadioValue]);

  const fetchItemData = async () => {
    if (searchData.length <= 3) return;

    const obj = {
      v_AnyDate: searchData,
      v_Type: selectedRadioValue
    };
    const response = await notionCatagorySearchAPI(obj);

    if (response?.length) {
      let GridID = response.map((obj, i) => ({
        ...obj,
        id: i + 1
      }));
      setSearchGridData(GridID);
    } else {
      setSearchGridData([]);
    }
  };

  const fCheckKey = (event) => {
    console.log(event.keyCode === 40, event.key);

    setTimeout(() => {
      if (event.keyCode === 40 && searchGridData.length > 0) {
        var selectedLength = document.getElementsByClassName('Mui-selected').length;
        if (selectedLength === 0) {
          let tabel = document.getElementById('table-main-grid-4');
          tabel?.getElementsByClassName('MuiDataGrid-row')[0].firstChild?.focus();
        }
      }
    }, 100);
  };

  const handleCloseModal = () => {
    setSearchData('');
    setSearchGridData([]);
    setSelectedRadioValue('C');
    handleClose();
  };
  return (
    <div>
      <Modal open={open} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: vMediaQuery ? '95%' : xMediaQuery ? '95%' : '65%',
            backgroundColor: ' #ffffff',
            borderRadius: 3,
            boxShadow: 6
          }}>
          <Box className="modal-head">
            <Typography variant="h6" marginLeft={1}>
              {xTitle}
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon sx={{ color: '#ffffff' }} />
            </IconButton>
          </Box>
          <Divider
            sx={{
              '&.MuiDivider-root': {
                borderColor: '#efeaea !important'
              }
            }}
          />

          {/* <Grid container spacing={3} padding={vMediaQuery ? 2 : 3} className="ItemRequest-filter"> */}
          <Box className={vMediaQuery ? 'px-3 pb-3' : 'px-4 py-4'}>
            <Grid container spacing={2} paddingBottom={2}>
              <Grid item xs={2} sm={1}>
                <CmpTypography xcomponent={'span'} xText={'Any Data'} />
              </Grid>
              <Grid item xs={1} sm={0.5}>
                <CmpTypography xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={9} sm={10.5}>
                <TextField
                  fullWidth
                  name="anyData"
                  id="anyData"
                  autoComplete="off"
                  value={searchData}
                  onChange={(e) => setSearchData(e.target.value)}
                  onKeyDown={(e) => {
                    fCheckKey(e);
                    if (e.key === 'Enter' || e._reactName === 'onClick') {
                      fetchItemData();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                        <SearchIcon onClick={fetchItemData} />
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      padding: '8px 6px !important'
                    },
                    '& .MuiOutlinedInput-root': {
                      paddingRight: ' 6px !important'
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={2} sm={1}>
                <CmpTypography xcomponent={'span'} xText={'Type'} />
              </Grid>
              <Grid item xs={1} sm={0.5}>
                <CmpTypography xcomponent={'span'} xText={':'} />
              </Grid>
              <Grid item xs={9} sm={10.5}>
                <FormControl
                  component="fieldset"
                  sx={{
                    paddingX: '4px',
                    '& .MuiRadio-root': {
                      padding: '4px !important'
                    }
                  }}>
                  {/* <FormLabel>Type</FormLabel> */}
                  <RadioGroup
                    row
                    defaultValue="C"
                    value={selectedRadioValue}
                    onChange={handleRadioChange}>
                    <FormControlLabel
                      value="C"
                      control={<Radio size="small" />}
                      label="Category"
                      sx={{ mr: 8 }}
                    />
                    <FormControlLabel
                      value="B"
                      control={<Radio size="small" />}
                      label="Brand"
                      sx={{ mr: 8 }}
                    />
                    <FormControlLabel value="I" control={<Radio size="small" />} label="Items" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          {/* </Grid> */}
          <div className={vMediaQuery ? 'px-3 pb-3' : 'px-4 pb-4'}>
            <Box>
              <div id="table-main-grid-4" style={{ height: '50vh', width: '100%' }}>
                <DataGrid
                  className="notion-grid"
                  rowHeight={30}
                  headerHeight={40}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even-row' : 'mui-odd-row'
                  }
                  sx={{
                    border: 1,
                    borderColor: 'primary.light'
                  }}
                  height={20}
                  getRowId={(row) => row.id}
                  rows={searchGridData?.length > 0 ? searchGridData : []}
                  columns={columns}
                  experimentalFeatures={{ newEditingApi: false }}
                  hideFooter={searchGridData?.length < 100}
                  onRowClick={(params) => {
                    onRowSelect(params.row);
                    setTimeout(() => {
                      handleCloseModal();
                    }, 200);
                  }}
                  onCellKeyDown={(params, e) => {
                    if (e.key === 'Enter') {
                      onRowSelect(params.row);
                      handleCloseModal();
                    }
                  }}
                />
              </div>
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ItemFieldSearchModal;
